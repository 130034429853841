.polygon-editor {
    position: relative;
    display: inline-block;
  }
  
  .editable-image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .polygon-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  polygon {
    fill: rgba(0, 0, 255, 0.3);
    stroke: blue;
    stroke-width: 2;
    pointer-events: auto;
    cursor: pointer;
  }
  
  .selected-polygon polygon {
    fill: rgba(0, 255, 0, 0.3);
    stroke: green;
  }
  
  .polygon-point {
    fill: red;
    cursor: pointer;
    pointer-events: auto;
  }