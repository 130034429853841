@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

body {
  margin: 0;
  font-family: "Segoe UI", "Open Sans", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.grey-title-container {
  /* text-align: center; */
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: bold;
  background-color: #f5f5f5;
}

.grey-bordered-container {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.white-bordred-container {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  border: 1px solid lightgrey;
  background-color: white;
  border-radius: 10px;
}

.grey-bordered-submission-container {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  /* border: 1px solid lightgrey; */
  border-radius: 10px;
  background-color: #f5f5f5;
}

.description-li {
  margin: 15px;
}

.link {
  color: inherit;
  text-decoration: none;
}

.link:hover {
  color: #1976d2;
  text-decoration: none;
}

.link:active {
  color: #1976d2;
}

.pattern-body::before {
  content: "";
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #f2f2f296; /* set the background color */
  clip-path: polygon(0 0, 100% 65%, 100% 100%, 0% 100%);
  max-width: 1300px; /* set the maximum width of the shape */
  z-index: -1;
}
